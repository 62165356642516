//align content parallel
.aligning-content {
    align-self: center;
}
//transition for invalid messages
.invalid-feedback {
    opacity: 0;
    transition: all 0.35s ease-out;
    display: block !important;
    height: 18px;
    line-height: 1.2;
    margin-top: 0.15rem;
    &.custom-invalid {
        opacity: 1;
    }
}
.was-validated :invalid ~,
.is-invalid ~ {
    .invalid-feedback,
    .invalid-tooltip {
        opacity: 1;
        transition: all 0.35s ease-out;
    }
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url('');
}
//custom height for texteditor
.editor-height {
    .EasyMDEContainer {
        .CodeMirror {
            height: 150px;
        }
    }
}
.areaTinyInputEditor.disabled {
    pointer-events: none;
    opacity: 0.7;
}
//statusbar display none
.editor-statusbar {
    display: none;
}

//size for asset box
.asset-box {
    background-color: $gray-100;
    height: 200px;
    width: 200px;
    img {
        width: 100%;
        height: 100%;
    }
    h5 {
        margin: 0;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
    }
    iframe[style]:not(#launcher-frame, #lightbox-frame, #widget-frame).video-block {
        width: 100%;
    }
}

//floating label overwrite on textarea
.custom-textarea {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    .form-control {
        border: none;
        padding-top: 0px !important;
        margin-top: 30px;
    }
    .invalid-feedback {
        position: absolute;
    }
}
// Login page mg{Email is sent to authorize this device.} width on mobile device
@media screen and (max-width: 375px) {
    .account-pages {
        .invalid-feedback {
            max-width: 53%;
        }
    }
}
.custom-button-shadow {
    &:focus {
        outline: 0 !important;
        box-shadow: none;
    }
}

/*
* Custom css for input tpye select
* For system admin stretch form consistency
*/
.custom-select {
    select,
    option {
        width: auto;
        min-width: 230px;
        max-width: 235px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.change-log {
        select,
        option {
            min-width: 220px;
            max-width: 222px;
        }
    }
}
/*
* Select box and radio button - centralize css for cursor pointer
* Add css on 'form-select' class for select box and 'form-check input' for radio button
*/
.form-select,
.form-check input {
    cursor: pointer;
    &:disabled {
        cursor: auto;
    }
}

/*
* add border(for error msg) on textarea when we use custom-textarea class
*/
.custom-textarea {
    &.is-invalid {
        border-color: #e7706a;
    }
}
.form-floating.is-invalid {
    .form-control:not(.is-invalid) {
        border-color: #e7706a;
    }
}

//for spacing above select options
@media only screen and (max-width: 768px) {
    .custom-spacing {
        margin-top: 20px;
    }
}

//custom font size
.custom-font {
    font-size: 12px;
}

// react datepicker input cursor
.react-datepicker-wrapper {
    input {
        &:hover {
            cursor: pointer;
        }
    }
    &:hover {
        cursor: pointer;
    }
}

//react datepicker input color
.react-datepicker-wrapper {
    .input-group {
        background-color: $gray-100 !important;
        border-color: $gray-100 !important;
        .form-control-light {
            background-color: transparent !important;
            border-color: transparent !important;
        }
        .input-group-append {
            margin-left: -45px !important;
        }
    }
}
//block disabled css
.block-disabled {
    opacity: 0.5;
    pointer-events: none;
}
/*
 * filter by brand list modal broadcast aler checkbox cursor pointer
 * added custom class - filter-brand-list
 */
.filter-brand-list.custom-listgroup {
    button {
        cursor: initial;
    }
    .form-check,
    input {
        cursor: pointer;
    }
}

//@at-root
.export-report-modal,
.print-report-modal {
    .form-select {
        width: 100%;
        min-width: auto;
    }
    .alert-info {
        padding: 0.75rem 0.95rem;
    }
}

//
.create-user-group {
    .list-group-item.active {
        z-index: initial;
    }
}
/*tooltip icon alignment
*for text and tooltip
*/
.custom-tooltip-align {
    line-height: 0;
    margin-top: 0.375rem;
    i {
        vertical-align: super;
    }
}

//custom file css
.custom-file {
    cursor: pointer;
    input[type='text'] {
        height: 35px;
        pointer-events: none;
    }
    .input-group-text {
        height: 35px;
        width: 105px;
        span {
            margin: auto;
        }
    }
}

/*
 * Add css for select box width auto
 * In system setting site page
 * add class Select-number-panel
 */
.Select-number-panel select.form-select {
    width: auto;
}

/**
 * Css for title filed
 * Add css on FormInput - input-title
 */
.input-title {
    .form-control {
        min-height: 58px;
    }
}

/*
 * Switch UI
 */
.form-switch {
    .form-check-input,
    .form-check-input:focus {
        background-image: url(../../../../images/switch-bg.svg);
        border: 1px solid #6d797e;
    }
}
.form-switch {
    .form-check-input:disabled,
    .form-check-input:disabled:focus {
        background-image: url(../../../../images/switch-bg.svg);
    }
}
.form-switch {
    .form-check-input:checked,
    .form-check-input:checked:focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        border: 1px solid #00b0ab;
    }
}

//
.fy-rollover-date {
    padding-bottom: 4px;
}

//------form.scss----
.input {
    left: auto;
    right: auto;
    float: left;
    padding: 0 0.75rem;
}
label {
    font-weight: 400;
}
.form-check {
    display: inline-block;
}
.form-check-enabled {
    padding-left: 2.2rem;
}
textarea.form-control {
    border: 1px solid #dee2e6;
    height: 100px;
}
textarea {
    &[height] {
        min-height: 150px !important;
    }
}
// pointer on toggle switch
.form-switch {
    input {
        cursor: pointer;
    }
}

//-------------------- _forms-custom-style.scss --------------------------------\
.form-floating {
    .form-control,
    .form-control:focus,
    > .form-select {
        color: #2b2f31;
        font-weight: 500;
    }
}

.create-new-user-form {
    .custom-label-style {
        margin-left: 0.5rem !important;
    }
}

.alert-wrapper {
    .text-info {
        color: #36a6c7 !important;
        margin-bottom: 24px;
    }
}

.internal-review-input {
    input,
    input:active,
    input:focus {
        border-color: $warning;
        border-style: dashed;
    }
}

/**
 * Role type UI - edit user
 */
.role-type {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    h5 {
        padding: 1rem 0.9rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: $gray-700;
        text-align: center;
        white-space: nowrap;
        background-color: $gray-200;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem 0 0 0.25rem;
        border-width: 0px 1px 0px 0px;
    }
    .role-name {
        margin-left: 20px;
        text-transform: lowercase;
        &:first-line {
            text-transform: capitalize;
        }
    }
}
// Profile photo upload
.edit-profile-information {
    @include asset-upload(220px);
}
// fund details photo upload
.fund-details,
.impact-details,
.default-fund-details-asset,
.default-impact-asset {
    @include asset-upload(240px);
}
.report-pb-cover-image {
    @include asset-upload(240px);
    .asset-box {
        height: 310px;
    }
}
.photo-upload {
    box-shadow: none;
    background-color: #eff4f5;
    width: 180px;
    height: 180px;
    img {
        height: auto;
        max-height: 180px;
    }
    h5 {
        position: absolute;
        float: left;
        top: 72px;
        text-align: center;
        font-size: 16px;
        width: 100%;
    }
}
.vcard-upload {
    width: 180px;
    height: 40px;
    img {
        height: auto;
        max-height: 180px;
    }
}

//Overriding platform css from tenant specific css -> fix for preview modal bg fluctuation
.rootComponent {
    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

// Fund Impact page asset
.tab-content {
    .asset-box {
        min-height: 200px;
        min-width: 200px;
        height: 200px;
        width: 200px;
        img {
            height: auto;
            width: auto;
            margin-left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            max-height: 200px;
            max-width: 200px;
            margin-top: 50%;
        }
    }
}

// gif issue fix on profile pic
.edit-profile-information {
    .photo-upload {
        img {
            width: 100%;
            margin-top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
        }
    }
}

.form-floating > label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

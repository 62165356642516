//Font Face

// font-family 1
@font-face {
    font-family: 'Greycliff CF';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Regular.otf');
}

@font-face {
    font-family: 'Greycliff CF - Bold';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Bold.otf');
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Greycliff CF';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Greycliff CF - Demi Bold';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Demi-Bold.otf');
}

@font-face {
    font-family: 'Greycliff CF - Regular Oblique';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Regular-Oblique.otf');
}

@font-face {
    font-family: 'Greycliff CF - Bold Oblique';
    src: url('https://old.asset.common.ovrture.com/ui/css/Org_50/fonts/Greycliff CF/Greycliff-CF-Bold-Oblique.otf');
}

@font-face {
    font-family: Roboto-Regular;
    src: url(https://old.asset.common.ovrture.com/ui/fonts/Roboto-Regular.ttf);
}

//Common CSS
.no-padding {
    padding: 0px;
}
body.authentication-bg {
    #rootHtml {
        padding-top: 0;
    }
}
/*****************************Themes******************************/
.common-login-wrapper {
    &.authentication-bg {
        background: #e8e8e8;
        .visitLinkWrapper {
            .visitLinkText {
                font-size: 14px;
                color: #5e5e5e;
                font-family: Roboto-Regular;
                font-weight: 400;
                margin-bottom: 21px;
                letter-spacing: 0.13px;
                .visitLink {
                    color: #00539f;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .login-container {
            .login-content {
                background-color: #ffffff;
                .image-container {
                    background-color: #ffffff !important;
                    border-bottom: 2px solid #e8e8e8;
                    .tenant-logo {
                        margin: auto;
                    }
                }

                .btn-primary {
                    border-radius: 2px;
                    background-color: #005cd5;
                    color: #ffffff;
                    &:active {
                        border: 1px solid #0058cc;
                        background-color: #4277f0;
                    }
                    &:hover {
                        border: 1px solid #0058cc;
                        background-color: #4277f0;
                    }
                    &.disabled {
                        color: #fff !important;
                        background-color: #6d797e !important;
                        border-color: #6d797e !important;
                    }
                }
            }
        }
        .footer {
            &.text-primary {
                color: #5e5e5e !important;
                &:hover {
                    color: #005cd5 !important;
                }
            }
            .link-primary {
                color: #5e5e5e;
                &:hover {
                    color: #005cd5;
                }
            }
        }
    }
}
/*UDEL*/ //Need To be Change After Sketch Update
.theme_50 {
    &.authentication-bg {
        .visitLinkWrapper {
            .visitLinkText {
                font-size: 14px;
                font-family: 'Greycliff CF - Regular Oblique';
                font-style: italic;
                margin-bottom: 20px;
                color: #526577;
                letter-spacing: 0.13px;
                .visitLink {
                    color: #00539f;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .login-container {
            .login-content {
                .image-container {
                    background-color: #00539f !important;
                    .tenant-logo {
                        width: 167px !important;
                    }
                }
            }
        }
    }
}
/************Lehigh************/
.theme_1 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    &.image-container {
                        .tenant-logo {
                            width: 179px !important;
                        }
                    }
                }
            }
        }
    }
}
/************CHOP************/
.theme_7 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    &.image-container {
                        .tenant-logo {
                            width: 212px !important;
                        }
                    }
                }
            }
        }
    }
}
/************MMC************/
.theme_8 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    &.image-container {
                        .tenant-logo {
                            width: 212px !important;
                        }
                    }
                }
            }
        }
    }
}
/************ONO************/
.theme_12 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    &.image-container {
                        .tenant-logo {
                            width: 212px !important;
                        }
                    }
                }
            }
        }
    }
}
/********Alzheimer's Association********/
.theme_61 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 347px !important;
                    }
                }
            }
        }
    }
}

/********NAT GEO********/
.theme_81 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #0f0f0f !important;
                    .tenant-logo {
                        width: 159px !important;
                    }
                }
            }
        }
    }
}

//WVUF
.theme_57 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 304px !important;
                    }
                }
            }
        }
    }
}
/********CWF********/
.theme_82 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #740909 !important;
                    .tenant-logo {
                        width: 256px !important;
                    }
                }
            }
        }
    }
}

/************EarthJustice************/
.theme_62 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    &.image-container {
                        .tenant-logo {
                            width: 212px !important;
                        }
                    }
                }
            }
        }
    }
}

//SJU
.theme_59 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 73px !important;
                    }
                }
            }
        }
    }
}
/********BCHT********/
.theme_26 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 298px !important;
                    }
                }
            }
        }
    }
}

/********Boston College********/
.theme_64 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 280px !important;
                    }
                }
            }
        }
    }
}
/********IU Foundation********/
.theme_48 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #7a1705 !important;
                    .tenant-logo {
                        width: 256px !important;
                    }
                }
            }
        }
    }
}

/********UCSF********/

.theme_18 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 104px !important;
                    }
                }
            }
        }
    }
}

/********VCU********/
.theme_17 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 171px !important;
                    }
                }
            }
        }
    }
}

/********USF********/
.theme_66 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 246px !important;
                    }
                }
            }
        }
    }
}

/********Tulane University********/
.theme_16 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 174px !important;
                    }
                }
            }
        }
    }
}

/********Mid Pacific University********/
.theme_70 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 284px !important;
                    }
                }
            }
        }
    }
}

/********Rice University********/
.theme_72 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 325px !important;
                    }
                }
            }
        }
    }
}

/********USNA********/
.theme_68 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 334px !important;
                    }
                }
            }
        }
    }
}

/********UBC********/
.theme_67 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 290px !important;
                    }
                }
            }
        }
    }
}

/********Mass General********/
.theme_69 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 250px !important;
                    }
                }
            }
        }
    }
}

/********Boston University********/
.theme_74 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 111px !important;
                    }
                }
            }
        }
    }
}

/********Memphis University********/
.theme_75 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 166px !important;
                    }
                }
            }
        }
    }
}

/********Campbell Hall********/
.theme_77 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 221px !important;
                    }
                }
            }
        }
    }
}

/********St Thomas********/
.theme_58 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 175px !important;
                    }
                }
            }
        }
    }
}

/********Child National Hospital********/
.theme_63 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 327px !important;
                    }
                }
            }
        }
    }
}

/********BRANSON SCHOOL********/
.theme_76 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 185px !important;
                    }
                }
            }
        }
    }
}

/********HBS********/
.theme_46 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 157px !important;
                    }
                }
            }
        }
    }
}

/********CCH********/
.theme_80 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 207px !important;
                    }
                }
            }
        }
    }
}

/********NCSU********/
.theme_28 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 292px !important;
                    }
                }
            }
        }
    }
}

/********TLS********/
.theme_83 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #ba0c2f !important;
                    .tenant-logo {
                        width: 224px !important;
                    }
                }
            }
        }
    }
}

/********OSU********/
.theme_84 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 293px !important;
                    }
                }
            }
        }
    }
}
/********UVA Law********/
.theme_86 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 269px !important;
                    }
                }
            }
        }
    }
}

/********UVA********/
.theme_19 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 218px !important;
                    }
                }
            }
        }
    }
}

/********TCU********/
.theme_27 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 109px !important;
                    }
                }
            }
        }
    }
}

/********Bucknell********/
.theme_29 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 140px !important;
                    }
                }
            }
        }
    }
}

/********Peddie********/
.theme_31 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #153256 !important;
                    .tenant-logo {
                        width: 178px !important;
                    }
                }
            }
        }
    }
}

/********IUHF********/
.theme_33 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 147px !important;
                    }
                }
            }
        }
    }
}

/********Dickinson********/
.theme_34 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #c8102e !important;
                    .tenant-logo {
                        width: 151px !important;
                    }
                }
            }
        }
    }
}

/********Mulhenberg********/
.theme_35 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #a41d36 !important;
                    .tenant-logo {
                        width: 146px !important;
                    }
                }
            }
        }
    }
}

/********CU Denver********/
.theme_36 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 260px !important;
                    }
                }
            }
        }
    }
}

/********Intermountain********/
.theme_37 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #036 !important;
                    .tenant-logo {
                        width: 245px !important;
                    }
                }
            }
        }
    }
}

/********MD Anderson********/
.theme_38 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 140px !important;
                    }
                }
            }
        }
    }
}

/********Stanford********/
.theme_40 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #8c1515 !important;
                    .tenant-logo {
                        width: 228px !important;
                    }
                }
            }
        }
    }
}

/********UC Davis********/
.theme_41 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #022b51 !important;
                    .tenant-logo {
                        width: 168px !important;
                    }
                }
            }
        }
    }
}

/********Depaul University********/
.theme_45 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #0057b7 !important;
                    .tenant-logo {
                        width: 137px !important;
                    }
                }
            }
        }
    }
}

/********Barnard********/
.theme_47 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #002f6c !important;
                    .tenant-logo {
                        width: 150px !important;
                    }
                }
            }
        }
    }
}

/********Auburn University********/
.theme_51 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #0a2240 !important;
                    .tenant-logo {
                        width: 274px !important;
                    }
                }
            }
        }
    }
}

/********Oglebay********/
.theme_54 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #487729 !important;
                    .tenant-logo {
                        width: 127px !important;
                    }
                }
            }
        }
    }
}

/********Amherst********/
.theme_55 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #40296a !important;
                    .tenant-logo {
                        width: 245px !important;
                    }
                }
            }
        }
    }
}

/********NIU********/
.theme_65 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 238px !important;
                    }
                }
            }
        }
    }
}

/********CMS********/
.theme_42 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 237px !important;
                    }
                }
            }
        }
    }
}

/********Ovrture University********/
.theme_52 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #008f8a !important;
                    .tenant-logo {
                        width: 270px !important;
                    }
                }
            }
        }
    }
}

/********Ovrture Health********/
.theme_53 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #008f8a !important;
                    .tenant-logo {
                        width: 300px !important;
                    }
                }
            }
        }
    }
}

/********Ovrture University POC********/
.theme_78 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #008f8a !important;
                    .tenant-logo {
                        width: 270px !important;
                    }
                }
            }
        }
    }
}

/********Ovrture Health POC********/
.theme_79 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #008f8a !important;
                    .tenant-logo {
                        width: 300px !important;
                    }
                }
            }
        }
    }
}

/********Susquehanna********/
.theme_25 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    background-color: #d95d00 !important;
                    .tenant-logo {
                        width: 300px !important;
                    }
                }
            }
        }
    }
}

/********Susquehanna********/
.theme_39 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 85px !important;
                    }
                }
            }
        }
    }
}

/********University of Miami********/
.theme_87 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 193px !important;
                    }
                }
            }
        }
    }
}

/********Stony Brook University********/
.theme_88 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 240px !important;
                    }
                }
            }
        }
    }
}

/********New York University********/
.theme_85 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 238px !important;
                    }
                }
            }
        }
    }
}

/******** M4BL ********/
.theme_89 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 112px !important;
                    }
                }
            }
        }
    }
}
/******** Children's Mercy ********/
.theme_90 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 206px !important;
                    }
                }
            }
        }
    }
}

/******** Avera Foundation ********/
.theme_92 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 147px !important;
                    }
                }
            }
        }
    }
}

/******** Wesleyan University ********/
.theme_93 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 250px !important;
                    }
                }
            }
        }
    }
}

/******** Ram's Club ********/
.theme_94 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 102px !important;
                    }
                }
            }
        }
    }
}

/******** TCH ********/
.theme_71 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 210px !important;
                    }
                }
            }
        }
    }
}

/******** Siena ********/
.theme_96 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 160px !important;
                    }
                }
            }
        }
    }
}

/******** SBP ********/
.theme_95 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 191px !important;
                    }
                }
            }
        }
    }
}

/******** SLU ********/
.theme_98 {
    &.authentication-bg {
        .login-container {
            .login-content {
                .image-container {
                    .tenant-logo {
                        width: 138px !important;
                    }
                }
            }
        }
    }
}

/*****************Themes End**********************/

.data-schema-page {
    .DataSchemaFields {
        .custom-listgroup {
            height: 714px;
            max-height: 714px;
        }
        .DataSchemaUploader {
            margin-top: 10px;
            .action-btn {
                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }
    .DataSchemaMappedFields {
        .card {
            margin-bottom: 0;
            height: 760px;
            max-height: 760px;
            overflow-y: scroll;
        }
        .DataSchemaFieldDetails {
            // height: 600px;
            // max-height: 600px;
            // overflow-y: scroll;
        }
    }
    .DataSchemaFieldDetails {
        // height: 421px;
        // max-height: 421px;
        // overflow: auto;
        .custom-shift-btn .btn {
            width: 38.33px;
        }
        .mandatoryField {
            margin-left: 15px;
        }
        [type='checkbox'][readonly] {
            pointer-events: none;
            opacity: 0.8;
        }
        .DatabaseField {
            background: #faebd7cc;
            padding-top: 15px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 0 35px 0;
        }
    }
}
.select-impact-data-fields-modal {
    .pagination-wrapper {
        display: none !important;
    }

    &.link-fund-modal .table-responsive.height-fixed.pagination-visible {
        height: 500px !important;
        .tbody {
            height: 445px;
        }
    }
    &.link-fund-modal .count {
        display: none;
    }
    &.link-fund-modal .table-responsive.height-fixed.mt-right-table {
        margin-top: 25px !important;
    }
}

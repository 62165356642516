//
// tables.scss
//

//@at-root
@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

//Table centered (Custom)
.table-centered {
    tbody {
        tr {
            height: 61.9px;
        }
    }
    td {
        padding: 0.7rem 0.95rem;
    }
}
//Custom min-height on tables
.table-responsive {
    // icon size larger css for table col
    .icon-large {
        font-size: larger;
    }
    thead {
        border: 1px solid #eff4f5;
        border-bottom-color: #edeff1;
    }
    tbody {
        border: 1px solid #eff4f5;
    }
    .analytics-dashboard-site-data-table,
    .analytics-dashboard-site-filter-data-table,
    .analytics-dashboard-site-opens-data-table,
    .analytics-dashboard-site-engagement-data-table,
    .analytics-dashboard-report-data-table,
    .analytics-dashboard-report-filter-data-table,
    .analytics-dashboard-report-opens-data-table,
    .analytics-dashboard-report-engagement-data-table {
        tbody {
            border-left: 0;
            border-right: 0;
        }
    }
}
// Custom table components (Custom)
.table {
    //Link fund modal icon size
    &.link-funds {
        .action-icon {
            font-size: 0.9rem;
        }
    }
    .action-icon {
        color: $gray-700;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;
        &:hover {
            color: $gray-700;
        }
        &.hover-primary {
            &:hover {
                color: $primary;
            }
        }
        &.hover-danger {
            &:hover {
                color: $danger;
            }
        }
        &.hover-info {
            &:hover {
                color: $info;
            }
        }
        &.hover-warning {
            &:hover {
                color: $warning;
            }
        }
        &.hover-secondary {
            &:hover {
                color: $secondary;
            }
        }
        &.hover-light {
            &:hover {
                color: $light;
            }
        }
        &.hover-dark {
            &:hover {
                color: $dark;
            }
        }
        &.hover-none {
            &:hover {
                color: $gray-600;
            }
        }
    }
    thead th {
        position: sticky !important;
        top: -1px;
        z-index: 10;
        &.columnFreezeble {
            z-index: 101;
        }
    }
    .freezedBorder::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 5px;
        background-color: #e9ebec;
    }
    .isRowHighlight {
        background-color: ghostwhite;
        border-style: double;
        border-color: #e2d7f3;
        border-width: 1px;
        td.columnFreezeble {
            background-color: ghostwhite;
        }
    }
    .isHaveCheckbox {
        [type='checkbox'] {
            margin-right: 5px;
            margin-top: 2px;
        }
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

//@at-root
.react-table {
    &.dashboard {
        th,
        tr td {
            &:first-child {
                width: 60px;
                max-width: 60px;
            }
            &:nth-child(2) {
                width: 150px;
            }
            &:nth-child(3) {
                min-width: 280px;
                max-width: 280px;
                @include ellipsis;
            }
            &:nth-child(4) {
                min-width: 100px;
            }
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                min-width: 180px;
                max-width: 170px;
                text-align: center;
            }
            &:last-child {
                width: 300px;
                min-width: 230px;
                max-width: 300px;
                text-align: center;
            }
        }
    }
    //system analytics table
    &.system-analytics {
        th,
        tr td {
            &:first-child {
                max-width: 300px;
                @include ellipsis;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                min-width: 160px;
                max-width: 160px;
                width: 160px;
                text-align: center;
            }
            &:last-child {
                min-width: 130px;
                max-width: 300px;
                width: 130px;
                text-align: center;
            }
        }
    }
    &.snapshot {
        th,
        tr td {
            &:first-child {
                width: 60px;
                max-width: 60px;
            }
            &:nth-child(2) {
                min-width: 180px;
                max-width: 180px;
                @include ellipsis();
            }
            &:nth-child(3) {
                min-width: 150px;
                max-width: 150px;
            }
            &:nth-child(4) {
                min-width: 120px;
                max-width: 120px;
            }
            &:nth-child(6) {
                width: 120px;
                text-align: center;
            }
            &:nth-child(5) {
                min-width: 150px;
                max-width: 150px;
                @include ellipsis();
            }
            &:nth-child(7) {
                min-width: 230px;
                max-width: 230px;
                @include ellipsis();
            }
            &:last-child {
                min-width: 100px;
                max-width: 100px;
                text-align: center;
            }
        }
    }
    td.columnFreezeble {
        position: sticky;
        left: 0;
        z-index: 1;
        background: white;
    }
    &.selectFundReport {
        th,
        tr td {
            white-space: break-spaces;
            min-width: 220px;
            max-width: 220px;
            &:first-child {
                min-width: 140px;
                max-width: 140px;
            }
            &:nth-child(2) {
                min-width: 170px;
                max-width: 170px;
            }
            &:nth-child(3) {
                min-width: 80px;
                max-width: 80px;
            }
            [type='checkbox'] {
                cursor: pointer;
            }
        }
        td {
            padding: 0rem 1.2rem;
        }
        mark.highlight {
            background-color: #e2d7f3;
            padding: 0.1em;
        }
        .row {
            display: inline-grid;
            grid-template-columns: repeat(11, 1fr);
        }
        .small-text {
            font-size: 0.7rem;
        }
        .custom-checkbox {
            padding: 8px !important;
            width: 20px;
            display: flex;
            align-items: center;
            height: 100%;
        }
        .border-secondary [type='checkbox'],
        #tableSelectAll {
            border-color: #6d797e !important;
        }
        .ml-c-4 {
            margin-left: -4px;
        }
        .custom-text {
            width: 205px;
            padding: 0 18px 0;
            margin-left: 4px;
        }
        i {
            margin-right: 5px;
        }
    }
    &.users-and-groups-table {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                min-width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(4) {
                min-width: 150px;
                max-width: 150px;
                width: 150px;
                text-transform: lowercase;
                &:first-line {
                    text-transform: capitalize;
                }
            }
            &:nth-child(5) {
                min-width: 600px;
                max-width: 600px;
                width: 600px;
                text-align: center;
                padding-top: 7px;
                padding-bottom: 0px;
                .gap-3 {
                    position: relative;
                    display: inline-flex;
                }
            }
            &:last-child {
                // width: 200px;
            }
        }
        th {
            &:nth-child(4) span {
                text-transform: lowercase;
                &:first-line {
                    text-transform: capitalize;
                }
            }
        }
        &.restore {
            th,
            tr td {
                &:first-child {
                    width: 60px;
                }
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(5),
                &:nth-child(6) {
                    min-width: 250px;
                    max-width: 250px;
                    width: 250px;
                    @include ellipsis;
                    text-align: left;
                }
                &:nth-child(4) {
                    min-width: 200px;
                    max-width: 200px;
                    width: 200px;
                    text-transform: lowercase;
                    &:first-line {
                        text-transform: capitalize;
                    }
                }
                &:last-child {
                    text-align: center;
                    width: 250px;
                }
            }
        }
    }
    &.crm-api-connection-mapping-table {
        th,
        tr td {
            &:nth-child(1) {
                display: none;
            }
            &:nth-child(2) {
                display: none;
            }
            &:nth-child(3) {
                min-width: 300px;
                max-width: 300px;
                width: 300px;
                @include ellipsis;
            }
            &:nth-child(4) {
                // min-width: 600px;
                max-width: 600px;
                // width: 600px;
                @include ellipsis;
            }
            &:nth-child(5) {
                min-width: 500px;
                max-width: 500px;
                width: 500px;
            }
        }
    }
    &.crm-api-connection-table {
        th,
        tr td {
            &[role='cell']:first-child,
            &[role='columnheader']:first-child {
                display: none;
            }
            &:nth-child(2) {
                min-width: 300px;
                max-width: 300px;
                width: 300px;
                @include ellipsis;
            }
            &:nth-child(3) {
                text-align: center;
                min-width: 350px;
                max-width: 350px;
                width: 350px;
            }
            &:nth-child(4) {
                text-align: center;
                width: 600px;
                // @include ellipsis;
            }
            &:last-child {
                // width: 200px;
            }
            .btn-disconnect {
                &.isDisconnected {
                    &:hover {
                        cursor: auto;
                    }
                }
                &.isConnected {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .btn-connect {
                &.isDisconnected {
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.isConnected {
                    &:hover {
                        cursor: auto;
                    }
                }
            }
            .action-icon {
                font-size: 1.2rem;
                padding: 0 30px;
                .mdi {
                    &.color-active {
                        color: $success;
                    }
                    &.color-paused {
                        color: $warning;
                    }
                    &.color-reauthorize {
                        color: $danger;
                    }
                    &.color-not_configured {
                        color: darkgray;
                    }
                    &.pause-field {
                        // &.color-active {
                        //     color: orange;
                        // }
                        // &.color-paused {
                        //     color: green;
                        // }
                        &.color-active {
                            color: gray;
                        }
                        &.color-paused {
                            color: gray;
                        }
                        &.color-reauthorize {
                            color: gray;
                        }
                        &.color-not_configured {
                            color: gray;
                        }
                    }
                }
            }
        }
    }
    &.review-and-approval-table-site {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 190px;
                max-width: 190px;
                @include ellipsis;
            }
            &:nth-child(4) {
                a {
                    max-width: 100%;
                    @include ellipsis;
                }
            }
            &:nth-child(5),
            &:nth-child(6) {
                width: 130px;
                max-width: 130px;
            }
            &:nth-child(7),
            &:nth-child(8),
            &:last-child {
                text-align: center;
            }
            &:nth-child(8) {
                width: 120px;
            }
        }
    }
    &.review-and-approval-table-report {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 190px;
                max-width: 190px;
                @include ellipsis;
            }
            &:nth-child(4) {
                a {
                    max-width: 100%;
                    @include ellipsis;
                }
            }
            &:nth-child(5) {
                min-width: 170px;
                width: 170px;
                max-width: 170px;
                @include ellipsis;
            }
            &:nth-child(8),
            &:nth-child(9),
            &:last-child {
                text-align: center;
            }
            &:nth-child(6) {
                width: 110px;
                max-width: 110px;
            }
            &:nth-child(7) {
                width: 140px;
                max-width: 140px;
            }
        }
    }
    &.review-and-approval-table-surveys {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(9),
            &:nth-child(5) {
                width: 150px;
                max-width: 150px;
                @include ellipsis;
            }
            &:nth-child(4),
            &:nth-child(6) {
                a {
                    max-width: 100%;
                    @include ellipsis;
                }
            }
            &:nth-child(8),
            &:nth-child(10),
            &:nth-child(11),
            &:last-child {
                width: 70px;
                text-align: center;
            }
        }
    }
    &.report-data {
        th,
        tr td {
            &:first-child {
                width: 30px;
            }
            &:nth-child(5) {
                min-width: 120px;
                width: 120px;
                max-width: 120px;
            }
            &:nth-child(2) {
                width: 350px;
                max-width: 350px;
                @include ellipsis;
            }
            &:nth-child(4) {
                width: 250px;
                max-width: 250px;
                @include ellipsis;
            }
            &:nth-child(3) {
                width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:last-child {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
                text-align: center;
            }
        }
        &.restore {
            th,
            tr td {
                &:nth-child(2) {
                    width: 250px;
                    max-width: 250px;
                }
                &:nth-child(3) {
                    width: 200px;
                    max-width: 200px;
                }
                &:nth-child(4) {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                    text-align: left;
                    @include ellipsis;
                }
                &:last-child {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                    text-align: center;
                }
            }
        }
        &.restore.details {
            th,
            tr td {
                &:nth-child(5),
                &:nth-child(6) {
                    min-width: 200px;
                    width: 200px;
                    max-width: 200px;
                    text-align: left;
                    @include ellipsis;
                }
            }
        }
        &.restore.performance {
            th,
            tr td {
                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 200px;
                    width: 200px;
                    max-width: 200px;
                    text-align: left;
                    @include ellipsis;
                }
            }
        }
        &.restore.donors,
        &.restore.impact {
            th,
            tr td {
                &:nth-child(2) {
                    width: 200px;
                    max-width: 200px;
                }
                &:nth-child(3) {
                    width: 153px !important;
                    max-width: 153px !important;
                }
            }
        }
        &.restore.donors {
            th,
            tr td {
                &:nth-child(2) {
                    width: 180px;
                    max-width: 180px;
                }
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(10) {
                    min-width: 145px;
                    width: 145px;
                    max-width: 145px;
                    text-align: left;
                    @include ellipsis;
                }
                &:nth-child(8),
                &:nth-child(9) {
                    min-width: 130px;
                    width: 130px;
                    max-width: 130px;
                    text-align: left;
                    @include ellipsis;
                }
                &:nth-child(6),
                &:nth-child(7) {
                    text-align: left;
                }
                &:last-child {
                    text-align: center;
                }
            }
        }
        &.restore.impact {
            th,
            tr td {
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                    text-align: left;
                    @include ellipsis;
                }
            }
        }
    }
    &.restore-content-panels,
    &.restore-assets {
        th,
        tr td {
            &:first-child {
                width: 30px;
            }
            &:nth-child(2) {
                width: 350px;
                max-width: 350px;
                @include ellipsis;
            }
            &:nth-child(3),
            &:nth-child(4) {
                width: 250px;
                max-width: 250px;
                @include ellipsis;
            }
            &:last-child {
                min-width: 200px;
                width: 200px;
                max-width: 200px;
                text-align: center;
            }
        }
    }
    &.report-data-linked-record {
        th,
        tr td {
            &:first-child {
                width: 300px;
                min-width: 300px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                text-align: center;
            }
        }
    }
    &.duplicate {
        th,
        tr td {
            &:first-child {
                width: 300px;
                min-width: 300px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                text-align: center;
            }
        }
    }
    &.brands {
        th,
        tr td {
            &:first-child {
                min-width: 50px;
                max-width: 50px;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                min-width: 170px;
                max-width: 170px;
                @include ellipsis;
            }

            &:nth-child(8),
            &:nth-child(9) {
                min-width: 130px;
                width: 130px;
                max-width: 130px;
                text-align: center;
            }
            &:nth-child(7) {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
                text-align: center;
            }
            &:last-child {
                width: 100px;
                max-width: 100px;
                text-align: center;
            }
        }
    }
    &.email-templates {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                min-height: 300px;
                width: 600px;
                max-width: 600px;
                @include ellipsis;
            }
            &:last-child {
                width: 250px;
                max-width: 250px;
                text-align: center;
            }
        }
    }
    &.palettes {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2) {
                width: 150px;
            }
            &:nth-child(3),
            &:nth-child(4) {
                min-width: 300px;
                width: 300px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                width: 200px;
                max-width: 200px;
                text-align: center;
            }
        }
    }
    &.standard-template {
        th,
        tr td {
            &:first-child {
                width: 80px;
                max-width: 80px;
            }
            &:nth-child(2) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 300px;
                max-width: 300px;
                @include ellipsis;
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(7) {
                width: 200px;
                text-align: center;
            }
        }
    }
    &.report-data-print-bridge {
        th,
        tr td {
            &:nth-child(2),
            &:nth-child(3) {
                min-width: 200px;
                max-width: 300px;
                @include ellipsis;
            }
            &:nth-child(4) {
                width: 300px;
                max-width: 300px;
                text-align: center;
            }
        }
    }
    &.manage-records-table {
        th,
        tr td {
            &:first-child {
                max-width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                max-width: 250px;
                @include ellipsis;
            }
            &:nth-child(5) {
                width: 100px;
                text-align: center;
            }
            &:nth-child(6) {
                width: 170px;
                text-align: center;
            }
            &:last-child {
                width: 250px;
                text-align: center;
            }
        }
    }
    &.link-funds {
        th,
        tr td {
            &:first-child {
                width: 60px;
                max-width: 60px;
            }
            &:nth-child(2),
            &:last-child {
                min-width: 120px;
                max-width: 120px;
                @include ellipsis;
            }
            &:last-child {
                padding-right: 25px;
            }
        }
        tbody {
            tr {
                height: 50px;
            }
        }
    }

    &.view-portfolio-table {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                width: 250px;
                min-width: 150px;
                max-width: 250px;
                @include ellipsis;
            }
            &:nth-child(6),
            &:nth-child(7) {
                width: 150px;
                text-align: center;
            }
            &:last-child {
                width: 200px;
                text-align: center;
            }
        }
    }
    &.manage-downloads {
        th,
        tr td {
            &:first-child {
                min-width: 100px;
                max-width: 150px;
                @include ellipsis;
            }
            &:nth-child(2) {
                min-width: 200px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                width: 180px;
                text-align: center;
            }
        }
    }
    &.analytics-table {
        th,
        tr td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 160px;
                max-width: 160px;
                @include ellipsis;
            }
            &:first-child,
            &:nth-child(5) {
                width: 180px;
                max-width: 180px;
                @include ellipsis;
            }
            &:nth-child(6) {
                width: 130px;
            }
            &:nth-child(7),
            &:nth-child(8) {
                width: 110px;
            }
            &:nth-child(9) {
                width: 120px;
            }
            &:nth-child(10) {
                width: 80px;
                text-align: center;
            }
            &:last-child {
                width: 230px;
                text-align: center;
            }
        }
    }
    &.analytics-dashboard-site-data-table,
    &.analytics-dashboard-report-data-table {
        th,
        tr td {
            &:first-child {
                min-width: 250px;
            }
            &:nth-child(2) {
                min-width: 100px;
            }
            &:nth-child(3) {
                min-width: 120px;
            }
        }
    }
    &.analytics-dashboard-site-filter-data-table,
    &.analytics-dashboard-report-filter-data-table,
    &.analytics-dashboard-site-opens-data-table,
    &.analytics-dashboard-site-engagement-data-table,
    &.analytics-dashboard-report-opens-data-table,
    &.analytics-dashboard-report-engagement-data-table {
        th,
        tr td {
            &:first-child {
                width: 300px;
                min-width: 300px;
                max-width: 300px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5) {
                min-width: 100px;
            }
        }
    }
    &.analytics-dashboard-site-filter-data-table,
    &.analytics-dashboard-site-opens-data-table,
    &.analytics-dashboard-site-engagement-data-table,
    &.analytics-dashboard-report-filter-data-table,
    &.analytics-dashboard-report-opens-data-table,
    &.analytics-dashboard-report-engagement-data-table {
        th {
            &:nth-child(2),
            &:nth-child(3) {
                background-color: #90addf;
                .btn {
                    color: #2c4149;
                }
            }
            &:nth-child(4),
            &:nth-child(5) {
                background-color: #627e4e;
                color: $white;
                .btn {
                    color: $white;
                }
            }
            &:nth-child(6) {
                background-color: #f79540;
                .btn {
                    color: #2c4149;
                }
            }
        }
    }
    &.view-reports-table {
        th,
        tr td {
            &:first-child {
                width: 40px;
                max-width: 40px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
                @include ellipsis;
            }
            &:nth-child(6) {
                min-width: 102px;
                width: 102px;
                max-width: 102px;
            }
            &:nth-child(7) {
                width: 150px;
                min-width: 150px;
                text-align: center;
            }
            &:nth-child(8) {
                width: 60px;
                text-align: center;
            }
            &:nth-child(9) {
                min-width: 165px;
                width: 165px;
                max-width: 165px;
            }
            &:nth-child(10) {
                min-width: 100px;
                width: 100px;
            }
            &:nth-child(11) {
                min-width: 110px;
                width: 110px;
                max-width: 110px;
                text-align: center;
            }
            &:last-child {
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                text-align: center;
            }
        }
        &.restore {
            th,
            tr td {
                &:nth-child(7),
                &:nth-child(8) {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                    @include ellipsis;
                    text-align: left;
                }
            }
        }
    }
    &.view-sites-table {
        th,
        tr td {
            &:first-child {
                min-width: 40px;
                width: 40px;
                max-width: 40px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                min-width: 177px;
                width: 177px;
                max-width: 177px;
                @include ellipsis;
            }
            &:nth-child(6) {
                width: 150px;
                min-width: 150px;
                text-align: center;
            }
            &:nth-child(7) {
                width: 90px;
                text-align: center;
            }
            &:nth-child(10) {
                min-width: 110px;
                width: 110px;
                max-width: 110px;
                text-align: center;
            }
            &:nth-child(8) {
                min-width: 170px;
                width: 170px;
                max-width: 170px;
            }
            &:nth-child(9) {
                min-width: 100px;
                width: 100px;
                max-width: 100px;
            }
            &:last-child {
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                text-align: center;
            }
        }
        &.restore {
            th,
            tr td {
                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 177px;
                    width: 177px;
                    max-width: 177px;
                    @include ellipsis;
                    text-align: left;
                }
            }
        }
    }
    &.surveys-table {
        th,
        tr td {
            &:first-child {
                width: 40px;
                max-width: 40px;
            }
            &:nth-child(2) {
                min-width: 170px;
                width: 170px;
                max-width: 170px;
                @include ellipsis;
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(10),
            &:nth-child(11) {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
                @include ellipsis;
            }
            &:nth-child(3),
            &:nth-child(6) {
                width: 80px;
                text-align: center;
            }
            &:nth-child(7),
            &:nth-child(8) {
                min-width: 120px;
                width: 120px;
                max-width: 120px;
            }

            &:nth-child(9) {
                min-width: 165px;
                width: 165px;
                max-width: 165px;
            }
            &:nth-child(10) {
                min-width: 100px;
                width: 100px;
            }
            &:nth-child(11) {
                min-width: 110px;
                width: 110px;
                max-width: 110px;
                text-align: center;
            }
            &:last-child {
                width: 250px;
                min-width: 250px;
                max-width: 250px;
                text-align: center;
            }
        }
    }
    &.manage-survey {
        th,
        tr td {
            &:first-child {
                width: 40px;
                max-width: 40px;
            }
            &:nth-child(2) {
                min-width: 170px;
                width: 170px;
                max-width: 170px;
                @include ellipsis;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(10) {
                min-width: 170px;
                width: 170px;
                max-width: 170px;
                @include ellipsis;
            }
            &:nth-child(6),
            &:nth-child(9) {
                width: 80px;
                text-align: center;
            }
            &:nth-child(7) {
                min-width: 200px;
                width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(8) {
                min-width: 140px;
                width: 140px;
                max-width: 140px;
                @include ellipsis;
            }
            &:nth-child(10) {
                min-width: 100px;
                width: 100px;
                text-align: center;
            }
            &:nth-child(11) {
                min-width: 110px;
                width: 110px;
                max-width: 110px;
                text-align: center;
            }
            &:last-child {
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                text-align: center;
            }
        }
    }

    &.export-archive-data {
        th,
        tr td {
            &:first-child,
            &:nth-child(2) {
                width: 160px;
                max-width: 160px;
                @include ellipsis;
            }
            &:nth-child(3) {
                min-width: 150px;
                width: 150px;
            }
            &:nth-child(4) {
                min-width: 100px;
                width: 100px;
            }
            &:last-child {
                width: 150px;
                max-width: 150px;
                text-align: center;
            }
        }
    }
    &.registered-devices {
        th,
        tr td {
            &:first-child {
                width: 120px;
                max-width: 120px;
                @include ellipsis;
            }
            &:nth-child(2) {
                width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(3) {
                max-width: 150px;
                width: 150px;
                text-align: center;
                @include ellipsis;
            }
            &:last-child {
                width: 150px;
                max-width: 150px;
                text-align: center;
            }
        }
    }
    &.manage-api-key {
        th,
        tr td {
            &:first-child {
                width: 350px;
                max-width: 350px;
                min-width: 350px;
                @include ellipsis;
            }
            &:first-child {
                width: 350px;
                max-width: 350px;
                min-width: 350px;
            }
            &:last-child {
                width: 300px;
                text-align: center;
            }
        }
    }
    &.multiple-sites,
    &.multiple-reports {
        &.multiple-sites-left,
        &.multiple-reports-left {
            th,
            tr td {
                &:first-child {
                    width: 60px;
                }
                &:nth-child(2) {
                    max-width: 100px;
                    @include ellipsis;
                }
            }
        }
        &.multiple-sites-right,
        &.multiple-reports-right {
            th,
            tr td {
                min-width: 117px;
                max-width: 117px;
                &:first-child {
                    width: 60px !important;
                    min-width: 60px;
                }
                &:nth-child(2) {
                    min-width: 117px !important;
                    max-width: 117px !important;
                    @include ellipsis;
                }
                &:nth-child(n + 3) {
                    button {
                        display: flex;
                        span:first-child {
                            max-width: 75px;
                            width: 70px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: inline-block;
                        }
                    }
                }
                &:nth-child(n + 4) {
                    button {
                        text-transform: lowercase;
                        span:first-child {
                            max-width: 65px;
                            width: 50px;
                            &:first-line {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
    &.created-access-key {
        tbody tr {
            height: 68px;
        }
        th,
        tr td {
            &:first-child,
            &:nth-child(2) {
                width: 330px;
                max-width: 330px;
                min-width: 330px;
                // @include ellipsis;
                white-space: inherit;
                word-break: break-all;
            }
            &:last-child {
                text-align: center;
            }
        }
    }
    &.users {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2) {
                width: 500px;
                max-width: 500px;
                @include ellipsis;
            }
            &:nth-child(3) {
                width: 400px;
                max-width: 500px;
                @include ellipsis;
            }
            &:last-child {
                width: 250px;
                max-width: 250px;
                text-align: center;
            }
        }
    }
    &.restore-system {
        th,
        tr td {
            &:first-child {
                width: 60px;
                max-width: 60px;
            }
            &:nth-child(2) {
                min-width: 180px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                max-width: 200px;
                width: 200px;
                text-align: center;
            }
        }
    }
    &.activity-log {
        th,
        tr td {
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(7) {
                max-width: 300px;
                @include ellipsis;
            }
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13) {
                min-width: 150px;
            }
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16) {
                min-width: 200px;
            }
        }
    }
    &.activity-log-detail {
        th,
        tr td {
            &:nth-child(2),
            &:nth-child(6),
            &:nth-child(3) {
                min-width: 200px;
                max-width: 300px;
                @include ellipsis;
            }
            &:nth-child(9) {
                min-width: 120px;
                text-align: center;
            }
            &:nth-child(4),
            &:nth-child(10) {
                min-width: 150px;
            }
            &:nth-child(5) {
                min-width: 170px;
                text-align: center;
            }
            &:nth-child(11),
            &:nth-child(12) {
                min-width: 200px;
            }
        }
    }
    &.basic-elements {
        th,
        tr td {
            &:first-child,
            &:nth-child(2) {
                min-width: 200px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                max-width: 400px;
                width: 400px;
                text-align: center;
            }
        }
    }
    &.broadcast {
        th,
        tr td {
            &:first-child {
                min-width: 30px;
                max-width: 30px;
            }
            &:nth-child(2) {
                min-width: 150px;
                max-width: 150px;
                width: 150px;
            }
            &:nth-child(3),
            &:nth-child(4) {
                min-width: 200px;
                max-width: 300px;
                @include ellipsis;
            }
            &:last-child {
                max-width: 350px;
                width: 350px;
                text-align: center;
            }
        }
    }
    &.std-pb {
        th,
        tr td {
            &:nth-child(2),
            &:nth-child(3) {
                min-width: 200px;
                max-width: 250px;
                @include ellipsis;
            }
            &:last-child {
                width: 300px;
                text-align: center;
            }
        }
    }
    &.edit-element-report-data {
        th,
        tr td {
            &:first-child,
            &:nth-child(2) {
                min-width: 200px;
                width: 600px;
                @include ellipsis;
            }
            &:last-child {
                width: 400px;
                max-width: 400px;
                text-align: center;
            }
        }
    }
    &.report-data-fund-description,
    &.report-data-fund-impact,
    &.report-data-fund-donor,
    &.report-data-fund-performance {
        th,
        tr td {
            &:first-child {
                min-width: 200px;
                max-width: 200px;
                @include ellipsis;
            }
            &:nth-child(2),
            &:nth-child(3) {
                min-width: 110px;
            }
            &:nth-child(4),
            &:last-child {
                min-width: 130px;
            }
        }
    }
    &.add-new-rgcp-html,
    &.map-rgcp-html {
        th,
        tr td {
            &:first-child {
                width: 60px;
                max-width: 60px;
            }
            &:nth-child(2) {
                width: 70px;
            }
            &:nth-child(5),
            &:nth-child(6) {
                width: 135px;
                max-width: 135px;
                @include ellipsis;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                width: 150px;
                max-width: 150px;
                @include ellipsis;
            }
            &:nth-child(6) {
                width: 100px;
                max-width: 100px;
            }
            &:last-child {
                width: 160px;
                max-width: 160px;
                text-align: center;
            }
        }
    }
    &.fund-type-modal,
    &.impact-type-modal {
        th,
        tr td {
            &:first-child {
                max-width: 60px;
                width: 60px;
            }
            &:nth-child(2) {
                min-width: 100px;
                width: 100px;
            }
            &:nth-child(3) {
                max-width: 180px;
                min-width: 180px;
                width: 180px;
                @include ellipsis;
            }
            &:nth-child(4) {
                min-width: 140px;
                width: 140px;
            }
            &:nth-child(5) {
                min-width: 150px;
                width: 150px;
                text-align: center;
            }
            // &:first-child {
            //     max-width: 200px;
            //     width: 200px;
            //     @include ellipsis;
            // }
            // &:nth-child(2) {
            //     min-width: 200px;
            //     width: 120px;
            // }
            // &:nth-child(3) {
            //     min-width: 200px;
            //     width: 120px;
            //     text-align: center;
            // }
        }
    }
    &.restore-records {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                min-width: 200px;
                max-width: 200px;
                width: 200px;
                @include ellipsis;
            }
            &:last-child {
                min-width: 200px;
                width: 200px;
                text-align: center;
            }
        }
    }
    &.current-editors,
    &.editors-list {
        th,
        tr td {
            &:first-child {
                width: 250px;
                max-width: 250px;
                min-width: 250px;
                @include ellipsis;
            }
            &:nth-child(2) {
                width: 200px;
                min-width: 200px;
                max-width: 200px;
            }
            &:last-child {
                width: 250px;
                min-width: 250px;
                max-width: 250px;
                white-space: pre-wrap;
            }
        }
    }
    &.restore-pa {
        th,
        tr td {
            &:first-child {
                width: 60px;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                min-width: 200px;
                max-width: 200px;
                width: 200px;
                @include ellipsis;
            }
            &:last-child {
                max-width: 200px;
                width: 200px;
                text-align: center;
            }
        }
        &.restore-tenants {
            th,
            tr td {
                &:nth-child(2) {
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                }
            }
        }
        &.restore-rgcp-panel {
            th,
            tr td {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    min-width: 170px;
                    max-width: 170px;
                    width: 170px;
                    @include ellipsis;
                }
                &:nth-child(4),
                &:nth-child(5) {
                    min-width: 130px;
                    max-width: 130px;
                    width: 130px;
                    @include ellipsis;
                }
            }
        }
        &.restore-palettes,
        &.restore-brands,
        &.restore-content-panels {
            th,
            tr td {
                &:nth-child(2) {
                    min-width: 150px;
                    max-width: 150px;
                    width: 150px;
                }
            }
        }
    }
    &.change-log-table {
        th,
        tr td {
            &:nth-child(4) {
                width: 70px;
                text-align: center;
            }
            &:nth-child(3) {
                text-transform: lowercase;
                &:first-line {
                    text-transform: capitalize;
                }
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(11),
            &:nth-child(13),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16) {
                min-width: 160px;
                max-width: 160px;
                width: 160px;
                @include ellipsis;
            }
            &:first-child,
            &:nth-child(9),
            &:nth-child(12),
            &:nth-child(10) {
                min-width: 200px;
                max-width: 200px;
                width: 200px;
                @include ellipsis;
            }
        }
        .view-details {
            font-size: 13px;
            padding: 6px;
            line-height: 1.2;
        }
    }
    &.view-details-table {
        th,
        tr td {
            &:first-child,
            &:last-child {
                white-space: break-spaces;
            }
            &:first-child {
                min-width: 300px;
                max-width: 300px;
                width: 300px;
            }
            &:last-child {
                min-width: 420px;
                max-width: 420px;
                width: 420px;
            }
        }
        // tr td {
        //     &:last-child {
        //         max-height: 200px;
        //         height: auto;
        //         overflow-y: scroll;
        //         display: inline-block;
        //     }
        // }
    }
}

// ellipsis on custom page having custom table UI
.custom-list-group {
    .selection-edit {
        max-width: 100%;
        @include ellipsis;
    }
}

// action icon size
.custom-action-icon {
    font-size: 1.2rem;
    &.hover-danger {
        &:hover {
            color: #fa5c7c;
        }
    }
    &.hover-primary {
        &:hover {
            color: #02a8b5;
        }
    }
}

// custom table header UI
.custom-table-header {
    background-color: #eff4f5;
    border-bottom: 1px solid #dce2e4;
    color: #2c4149;
    padding: 15.2px;
    vertical-align: middle;
    margin: 0px 0px;
    white-space: nowrap;
}

/**
 * pointer-events: none when no data found in table
 * disabled
 */
.sortable.disabled {
    pointer-events: none;
}
/**
*ellipsis on listgroup
*for link record modal
*/
.custom-list-group-ellipsis {
    @include ellipsis();
}

/*
 * css for create multiple site page pagination 
 *
*/
.small-table-custom-pagination {
    .pagination-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
        .pagination-rounded .page-link {
            margin: 0 1px !important;
            padding: 0.2rem 0.6rem;
        }
        .form-select {
            padding: 0.45rem 2.4rem 0.45rem 0.8rem;
        }
        .left {
            margin: 5px !important;
            &.me-3 {
                min-width: 85px;
                margin-right: 5px !important;
            }
        }
        .center {
            label {
                display: block;
            }
            input {
                width: 75px !important;
                min-width: 75px !important;
            }
        }
        .right {
            margin-top: 8px;
            width: 100%;
            justify-content: center;
        }
        .me-3 {
            margin-right: 0 !important;
            .me-1 {
                display: block;
                text-align: left;
            }
        }
        .goto-page-width {
            margin-left: 0 !important;
        }
    }
    //Filter width setting
    .filter-wrapper {
        .dropdown-menu.show {
            min-width: 275px;
            transform: translate3d(25px, 34px, 0px) !important;
        }
    }
}
//multiple sites/reports
.multiple-sites-page,
.multiple-reports-page {
    @include shifter-tables(850px, 745px, 820px, 700px, 620px, 790px);
    .card-header {
        .card-body {
            height: auto;
        }
    }
}
.multiple-reports-page .small-table-custom-pagination.mt-3 {
    margin-top: 1.4rem !important;
}
//Linked fund modal
.link-fund-modal {
    @include shifter-tables(600px, 500px, 580px, 455px, 375px, 517px);
    .count {
        position: absolute;
        transform: translate(24px, 22px);
        -webkit-transform: translate(24px, 22px);
    }
}

.manage-downloads-wrap {
    max-height: 400px;
}
.table-responsive.table-snapshot {
    min-height: 140px;
}
/**
 * multiple site, reports right table aligment
 */
.multiple-reports-right td button,
.multiple-sites-right td button {
    padding-left: 0;
    text-align: left;
}
// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    //Link fund modal icon size
    &.link-funds {
        .action-icon {
            font-size: 1rem;
        }
    }
    .action-icon {
        color: $gray-700;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;
        &:hover {
            color: $gray-700;
        }
    }
}
.pagination-wrapper {
    .me-3.left {
        min-width: 100px;
    }
}
.shadow-none {
    .btn-light.dropdown-toggle:focus {
        box-shadow: none;
    }
}

.dropdown-toggle {
    &:focus {
        box-shadow: none;
    }
}
@media (min-width: 992px) and (max-width: 1372px) {
    .multiple-sites-page,
    .multiple-reports-page {
        .card-body {
            padding: 1.5rem 0.9rem;
            .pagination-wrapper {
                font-size: 11px;
                .form-select {
                    font-size: 11px;
                    padding: 0.45rem 1rem 0.45rem 0.2rem;
                    background-position: right 0.3rem center;
                    background-size: 8px 8px;
                }
                .left.me-3 {
                    min-width: 70px;
                    margin: 0 !important;
                }
                .center input {
                    min-width: 55px !important;
                    font-size: 11px;
                }
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 991.98px) {
    .link-fund-modal {
        .small-table-custom-pagination {
            height: auto;
        }
        .height-fixed.table-auto-height {
            tbody {
                height: auto;
            }
        }
        .outer-wrapper {
            .inner-wrapper {
                .custom-shift-btn {
                    flex: 0 0 auto;
                    width: auto;
                }
            }
        }
    }
    .multiple-sites-page,
    .multiple-reports-page,
    .link-fund-modal {
        .card-body {
            height: auto;
        }
        .outer-wrapper {
            height: auto;
            .inner-wrapper {
                top: inherit;
                left: inherit;
                transform: inherit;
                position: inherit;
                display: flex;
                justify-content: space-around;
                width: 60%;
                margin: auto;
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 480px) {
    .link-fund-modal {
        .outer-wrapper {
            .inner-wrapper {
                width: 100%;
            }
        }
    }
}

// Not Enabled UI
.not-enabled {
    font-weight: normal;
    color: #e7ecec;
    i {
        font-size: 125px;
    }
    p {
        color: #dbddde;
        margin-bottom: 10px !important;
    }
}
// descriptor position on table
thead.table-light {
    .descriptor {
        line-height: 0;
        margin-top: -2px;
    }
}

// Soft delete edit icon disable css
.disabled-show-tooltip {
    opacity: 0.65;
    cursor: auto !important;
    &:hover {
        color: #6d797e !important;
    }
    &.custom-svg svg:hover path,
    &.custom-svg.text-success svg path,
    &.custom-svg.text-danger svg path {
        fill: #6d797e !important;
    }
}
.RestoreDeletedContentPanel {
    .glyphicon.glyphicon-text-background-cp {
        top: 0;
    }
    .glyphicon.glyphicon-text-background-cp-draft {
        margin-top: 0;
        top: 0;
    }
}
.crm-api-connection-data-flow {
    font-size: 14px;
    max-width: 450px;
    .flow-title {
        font-size: 15px;
    }
    .row {
        border-bottom: 1px solid lightgray;
        padding-bottom: 15px;
        &.mt-2 {
            margin-top: 1.3rem !important;
        }
        .form-switch {
            width: 260px;
            margin-bottom: 0.3rem !important;
            .form-check-label {
                font-weight: 600;
            }
        }
        .form-select {
            width: 260px;
            margin-left: 40px;
            font-size: 14px;
        }
        .form-select-para {
            width: 260px;
            margin-left: 40px;
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 12px;
            color: #bdbaba;
        }
    }
    .mb-3 {
        margin: 0 !important;
    }
}

.crm-api-connection-mapping {
    .row {
        .invalid-feedback {
            padding: 0;
        }
    }

    .card-header {
        display: none;
    }
}
.crm-api-connection-mapping-table {
    .invalid-feedback {
        display: none !important;
    }
}
.react-table {
    th {
        max-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &:not(.selectFundReport) th:not(:first-child) span.ellipsis,
    &.selectFundReport th span.ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
    }
}
.react-table.review-and-approval-table-site {
    th {
        &:nth-child(7),
        &:nth-child(8),
        &:last-child {
            max-width: 115px;
            width: 115px;
            min-width: 115px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: inherit;
            span.ellipsis {
                max-width: 80px;
            }
            button.descriptor {
                margin-top: -13px;
            }
        }
        &:nth-child(8) {
            max-width: 150px;
            width: 150px;
        }
    }
}
.react-table.review-and-approval-table-report {
    th {
        &:nth-child(8),
        &:nth-child(9),
        &:last-child {
            max-width: 115px;
            width: 115px;
            min-width: 115px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: inherit;
            span.ellipsis {
                max-width: 80px;
            }
            button.descriptor {
                margin-top: -13px;
            }
        }
    }
}
.react-table.review-and-approval-table-surveys {
    th {
        &:nth-child(10),
        &:nth-child(11),
        &:last-child {
            max-width: 125px;
            width: 125px;
            min-width: 125px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: inherit;
            span.ellipsis {
                max-width: 80px;
            }
            button.descriptor {
                margin-top: -13px;
            }
        }
    }
}
.react-table.analytics-dashboard-site-filter-data-table,
.react-table.analytics-dashboard-site-opens-data-table,
.react-table.analytics-dashboard-site-engagement-data-table,
.react-table.analytics-dashboard-report-filter-data-table,
.react-table.analytics-dashboard-report-opens-data-table,
.react-table.analytics-dashboard-report-engagement-data-table {
    th {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:last-child {
            max-width: 240px;
            width: 240px;
            min-width: 240px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: inherit;
            span.ellipsis {
                max-width: 100px;
            }
            button.descriptor {
                margin-top: -13px;
            }
        }
    }
}
